import React, { useMemo } from 'react';
import { ILoginProps } from '../types';
import { ELoginMode } from '../../../constants/enums';
import { StyledLoginContainer } from '../style';
import { UsernamePasswordMode } from '../LoginMode/username';
import { LoginIdMode } from '../LoginMode/loginId';
import { OtpMode } from '../LoginMode/OTP';
import { ProvidersMode } from '../LoginMode/providers';
import { useLoginPage } from '../useLoginPage.hook';

export const LoginButtonBlocks: React.FC<
    ILoginProps & { maxWidth?: string }
> = ({
    mode,
    openInfoModal,
    loginResponse,
    stopLoader,
    startLoader,
    loginButtonClickWrapper,
    setMode,
    otpProofKey,
    otpTokenKey,
    maxWidth = '400px'
}) => {
    const { publisherMetaData, isWebview } = useLoginPage();

    // Unused...
    // const [deepLink, setDeepLink] = useState<string>('');
    // useEffect(() => {
    //     if (!playerAuthData?.otpLinks) return;
    //     const findDeepLink = (platform: EPlatformType) => {
    //         playerAuthData?.otpLinks.forEach((link) => {
    //             if (link.platform === platform) {
    //                 setDeepLink(link.deepLink);
    //             }
    //         });
    //     };
    //     if (isIOS) {
    //         findDeepLink(EPlatformType.IOS);
    //     } else if (isAndroid) {
    //         findDeepLink(EPlatformType.ANDROID);
    //     } else {
    //         findDeepLink(EPlatformType.WEB);
    //     }
    // }, []);

    const openHowToFindId = () => {
        openInfoModal(
            publisherMetaData.integration.playersAuthentication.userTokenUrl
        );
    };

    const showUserTokenLink = useMemo(
        () =>
            (mode === ELoginMode.PROVIDERS || mode === ELoginMode.TOKEN_ID) &&
            publisherMetaData.integration.playersAuthentication.userTokenUrl &&
            publisherMetaData.integration.playersAuthentication.userTokenText,
        [publisherMetaData.integration.playersAuthentication]
    );

    return (
        <StyledLoginContainer maxWidth={maxWidth}>
            {mode === ELoginMode.PROVIDERS && (
                <ProvidersMode
                    loginResponse={loginResponse}
                    stopLoader={stopLoader}
                    startLoader={startLoader}
                    loginButtonClickWrapper={loginButtonClickWrapper}
                    setMode={setMode}
                />
            )}
            {mode === ELoginMode.USERNAME && (
                <UsernamePasswordMode
                    loginButtonClickWrapper={loginButtonClickWrapper}
                    submit={loginResponse}
                />
            )}
            {mode === ELoginMode.TOKEN_ID && (
                <LoginIdMode
                    loginButtonClickWrapper={loginButtonClickWrapper}
                    submit={loginResponse}
                />
            )}
            {(otpProofKey || mode === ELoginMode.OTP) && (
                <OtpMode
                    submit={loginResponse}
                    otpProofKey={otpProofKey}
                    otpTokenKey={otpTokenKey}
                    loginButtonClickWrapper={loginButtonClickWrapper}
                />
            )}
            {showUserTokenLink && !isWebview && (
                <p className="userTokenLink" onClick={openHowToFindId}>
                    {
                        publisherMetaData.integration.playersAuthentication
                            .userTokenText
                    }
                </p>
            )}
        </StyledLoginContainer>
    );
};
