import { AppchargeCheckout } from 'appcharge-checkout-reactjs-sdk';
import { useEffect, useRef, useState } from 'react';
import { CheckoutProps } from './Checkout.types';
import useApi from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { EOrderStatus } from '../../pages/checkout/checkout.types';
import { BootResponse } from '../../constants/apiResponses.types';
import {
    ECheckoutPageEvent,
    EEventsType,
    ELocalStorageKeys,
    EQueryParams,
    EStorePhase
} from '../../constants/enums';
import './style.scss';
import { AcCircularLoader, Overlay } from '@appcharge/shared-ui';
import useCustomEvents from '../../hooks/useCustomEvents';
import { localStorageUtil } from '../../utils';
import { useTranslation } from 'react-i18next';
import { AppchargeLocale } from 'appcharge-checkout-reactjs-sdk/lib/components/ui/AppchargeCheckout';

interface Session {
    token?: string;
    url?: string;
}
const MAX_TIMEOUT_OFFERS_REFETCH = 1000 * 60; // 1 minute

const Checkout = ({
    close,
    offerId,
    collectId,
    setProcessing,
    currencyCode
}: CheckoutProps) => {
    const navigate = useNavigate();
    const [session, setSession] = useState<Session>({});
    const offersFetchInterval = useRef<any>();
    const [isSettled, setIsSettled] = useState<boolean>(!!collectId);
    const [resultIframeSrc, setResultIframeSrc] = useState<string>('');
    const [iframeLoading, setIframeLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const API = useApi({});
    const customEvents = useCustomEvents();
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const isUsingApiV2 =
        publisherMetaData.featureFlags?.store_daily_bonus_badge;

    useEffect(() => {
        if (collectId) return;
        setProcessing(true);
        const createSessionData = {
            data: {
                offerId
            }
        };
        if (isUsingApiV2) {
            API.createCheckoutSessionV2.mutate(createSessionData, {
                onSuccess: (sessionData) => {
                    setSession({
                        url: sessionData.data.url,
                        token: sessionData.data.checkoutSessionToken
                    });
                    document.body.classList.add('screen-lock');
                },
                onError: () => {
                    navigate('../failed?msg=creating order error');
                }
            });
        } else {
            API.createCheckoutSession.mutate(createSessionData, {
                onSuccess: (sessionData) => {
                    setSession({
                        url: sessionData.data.url,
                        token: sessionData.data.checkoutSessionToken
                    });
                    document.body.classList.add('screen-lock');
                },
                onError: () => {
                    navigate('../failed?msg=creating order error');
                }
            });
        }
    }, [offerId]);

    useEffect(() => {
        if (!collectId) return;
        setResultIframeSrc(`${window.location.origin}/checkout/${collectId}`);
    }, [collectId]);

    const onSuccess = (params: any) => {
        setResultIframeSrc(
            `${window.location.origin}/checkout/${params.orderId}?currencyCode=${currencyCode}`
        );
    };

    const onFailure = (params: any) => {
        setIframeLoading(false);
        setResultIframeSrc(
            `${window.location.origin}/failed?error=${EOrderStatus.CHARGE_FAILED}&order_id=${params.orderId}&${EQueryParams.IS_IFRAME}=true`
        );
    };

    useEffect(() => {
        const eventHandler = (massageEvent: any) => {
            if (
                massageEvent.origin !== window.location.origin &&
                !(massageEvent.origin as string)
                    .toLowerCase()
                    .includes('checkout-v2')
            )
                return;
            const { params, event } = massageEvent.data;
            console.log('checkout event', event);
            switch (event) {
                case ECheckoutPageEvent.CHECKOUT_OPENED:
                    if (
                        localStorageUtil.get(
                            ELocalStorageKeys.IS_FREE_ORDER_SELECTED
                        )
                    ) {
                        isUsingApiV2
                            ? API.getOffersV2.refetch()
                            : API.getOffers.refetch();
                        offersFetchInterval.current = setInterval(() => {
                            isUsingApiV2
                                ? API.getOffersV2.refetch()
                                : API.getOffers.refetch();
                        }, 1000);
                    }
                    localStorageUtil.remove(
                        ELocalStorageKeys.IS_FREE_ORDER_SELECTED
                    );
                    break;
                case ECheckoutPageEvent.PAYMENT_INTENT_SUCCESS:
                    isUsingApiV2
                        ? API.getOffersV2.refetch()
                        : API.getOffers.refetch();
                    offersFetchInterval.current = setInterval(() => {
                        isUsingApiV2
                            ? API.getOffersV2.refetch()
                            : API.getOffers.refetch();
                    }, 1000);
                    break;
                case ECheckoutPageEvent.BACK_TO_STORE:
                    customEvents.sendCustomEvent(
                        EEventsType.COMPLETE_SCREEN_BACK_TO_SHOP,
                        {},
                        EStorePhase.POST_LOGIN
                    );

                    offersFetchInterval.current &&
                        clearInterval(offersFetchInterval.current);
                    document.body.classList.remove('screen-lock');
                    close();

                    localStorageUtil.remove(
                        ELocalStorageKeys.CURRENT_AVAILABILITY
                    );
                    break;
                case ECheckoutPageEvent.BACK_TO_GAME:
                    customEvents.sendCustomEvent(
                        EEventsType.COMPLETE_SCREEN_BACK_TO_GAME,
                        {},
                        EStorePhase.POST_LOGIN
                    );
                    window.location.assign(params.returnToGameLinkAddress);
                    close();
                    offersFetchInterval.current &&
                        clearInterval(offersFetchInterval.current);
                    document.body.classList.remove('screen-lock');
                    break;
                case ECheckoutPageEvent.SUPPORT:
                    navigate(params.supportUrl);
                    break;
                case ECheckoutPageEvent.ORDER_COMPLETED_SUCCESS:
                    offersFetchInterval.current &&
                        setTimeout(() => {
                            clearInterval(offersFetchInterval.current),
                                MAX_TIMEOUT_OFFERS_REFETCH;
                        });
                    break;
                case ECheckoutPageEvent.ORDER_COMPLETED_FAILED:
                    offersFetchInterval.current &&
                        setTimeout(() => {
                            clearInterval(offersFetchInterval.current),
                                MAX_TIMEOUT_OFFERS_REFETCH;
                        });
                    break;
            }
        };

        window.addEventListener('message', eventHandler);

        return () => {
            window.removeEventListener('message', eventHandler);
        };
    }, []);

    // This is "hack" for Firefox issue with "onLoad" call
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data === 'iframe-loaded') {
                setIframeLoading(false);
                event.source?.postMessage('stop-messages');
            }
        };
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    return (
        <>
            {session.url && session.token && (
                <div
                    className={isSettled ? 'hide-iframe' : ''}
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        height: '100lvh',
                        width: '100vw',
                        zIndex: '1000'
                    }}
                >
                    <AppchargeCheckout
                        checkoutUrl={session.url}
                        sessionToken={session.token}
                        playerId={
                            localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)
                                .playerId
                        }
                        referrerUrl={''}
                        onClose={() => {
                            close();
                            document.body.classList.remove('screen-lock');
                        }}
                        onInitialLoad={() => {
                            setProcessing(false);
                            document.body.classList.add('screen-lock');
                        }}
                        onOrderCompletedSuccessfully={onSuccess}
                        onOrderCompletedFailed={onFailure}
                        onPaymentIntentSuccess={() => setIsSettled(true)}
                        locale={i18n.language as AppchargeLocale}
                    />
                </div>
            )}
            {isSettled && (
                <Overlay overlayPercentage={80}>
                    {iframeLoading && (
                        <AcCircularLoader text={t('processing')} />
                    )}
                    <iframe
                        title={'checkout resolve'}
                        className="resolve-iframe"
                        src={resultIframeSrc}
                        style={{
                            visibility: iframeLoading ? 'hidden' : 'visible',
                            transition: 'visibility 1s easy-in-out'
                        }}
                    ></iframe>
                </Overlay>
            )}
        </>
    );
};

export default Checkout;
