import { ActionButton } from '@appcharge/shared-ui';
import { BackToGameButtonProps } from './BackToGameButton.types';
import { sendMessageToIframeParent } from '../../utils';
import { ECheckoutPageEvent } from '../../constants/enums';

const BackToGameButton = ({
    btnText,
    btnColor,
    returnToGameLinkAddress,
    border,
    useNewCheckout,
    borderRadius,
    width
}: BackToGameButtonProps) => {
    const returnToGame = () => {
        if (useNewCheckout) {
            sendMessageToIframeParent(ECheckoutPageEvent.BACK_TO_GAME, {
                returnToGameLinkAddress
            });
        } else {
            window.location.assign(returnToGameLinkAddress);
        }
    };
    return (
        <ActionButton
            text={btnText}
            action={returnToGame}
            colors={btnColor}
            border={border}
            borderRadius={borderRadius}
            width={width}
        />
    );
};

export default BackToGameButton;
