import { isMobile, osName, browserName } from 'react-device-detect';
import { BootResponse } from '../../constants/apiResponses.types';
import useApi from '../../hooks/useApi';
import { loginPageService } from './login-page.service';
import { useCallback, useMemo } from 'react';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
    EBundlesInternalViewModel,
    EEventsType,
    EStorePhase,
    ESupportType
} from '../../constants/enums';
import { useLocation, useNavigate } from 'react-router-dom';

export const useLoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const API = useApi({
        platform: isMobile ? osName : browserName
    });

    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const { sendCustomEvent } = useCustomEvents();

    const isWebview = useMemo(
        () => loginPageService.isWebviewBrowser(),
        [navigator.userAgent, location]
    );

    const handleWebviewEvents = useCallback(
        (eventData: any) => {
            const options = eventData.data
                ? Object.assign(
                      eventData.data,
                      loginPageService.getLoginEventsData({
                          eventName: EEventsType.WEBVIEW_LAND,
                          gameName: publisherMetaData.publisher?.storeTabName
                      })
                  )
                : loginPageService.getLoginEventsData({
                      eventName: EEventsType.WEBVIEW_LAND,
                      gameName: publisherMetaData.publisher?.storeTabName
                  });
            sendCustomEvent(eventData.event, options, EStorePhase.PRE_LOGIN);
        },
        [publisherMetaData.publisher]
    );

    const onContactSupport = useCallback(() => {
        sendCustomEvent(
            EEventsType.SUPPORT_FORM_OPEN,
            {
                phase: EStorePhase.PRE_LOGIN,
                supportModel:
                    publisherMetaData.supportConfiguration?.supportModel
            },
            EStorePhase.PRE_LOGIN
        );
        if (publisherMetaData.supportConfiguration?.externalSupportUrl) {
            API.sendEmptySupport(
                publisherMetaData.storeTheme.publisherId,
                ESupportType.PRE_LOGIN,
                EStorePhase.PRE_LOGIN
            );
            window.location.href =
                publisherMetaData.supportConfiguration.externalSupportUrl;
        } else {
            navigate(
                `/support/${ESupportType.PRE_LOGIN}/${EStorePhase.PRE_LOGIN}`
            );
        }
    }, [publisherMetaData]);

    const isProductMadness = [
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.BIG_FISH_CASINO
    ].includes(
        publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel || ''
    );

    return {
        API,
        publisherMetaData,
        isWebview,
        isProductMadness,
        handleWebviewEvents,
        onContactSupport
    };
};
