import React, { useEffect, useMemo } from 'react';
import AppleLogin from 'react-apple-login';
import { ProvidersModeProps } from '../types';
import { isDesktop } from 'react-device-detect';
import useUrlQuery from '../../../hooks/useUrlQuery';
import useUrlHash from '../../../hooks/useUrlHash';
import {
    EAppleResponseType,
    ELoginMethods,
    ELoginMode,
    EOTPTextModel,
    ESessionStorageKeys
} from '../../../constants/enums';
import { FacebookLogin } from '../Externals/Facebook';
import { GoogleLogin } from '../Externals/Google';
import { LoginButton } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';
import { localStorageUtil } from '../../../utils';

export const ProvidersMode: React.FC<ProvidersModeProps> = ({
    setMode,
    stopLoader,
    startLoader,
    loginResponse,
    loginButtonClickWrapper
}) => {
    const { publisherMetaData, isWebview } = useLoginPage();
    const { t } = useTranslation();
    const isAppleRedirect = useUrlQuery('apple') === 'true';
    const facebookAccessToken = useUrlHash('#access_token');
    const appleIdToken = useUrlHash('id_token');

    const playerAuthData = publisherMetaData.integration.playersAuthentication;

    const { otpButton } = publisherMetaData.storeTheme.login;

    const showOtpButton = useMemo(
        () =>
            playerAuthData.otpOn &&
            (isDesktop
                ? playerAuthData?.otpTextModel &&
                  playerAuthData?.otpTextModel !== EOTPTextModel.MOBILE_ONLY
                : true),
        [playerAuthData]
    );

    const facebookResponseHandler = () => {
        if (!facebookAccessToken) return;
        startLoader();
        const data = {
            userToken: facebookAccessToken,
            authMethod: ELoginMethods.FACEBOOK
        };
        localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
        loginResponse(data);
    };

    const appleResponseHandler = (response: any) => {
        const token = response.code || response;
        if (token) {
            const data = {
                userToken: token,
                authMethod: ELoginMethods.APPLE
            };
            localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
            loginResponse(data);
        }
    };

    useEffect(() => {
        if (isAppleRedirect) startLoader();
        if (facebookAccessToken) facebookResponseHandler();
        if (appleIdToken) appleResponseHandler(appleIdToken);
        return () => {
            stopLoader();
        };
    }, [isAppleRedirect, facebookAccessToken, appleIdToken]);

    return (
        <>
            {showOtpButton && (
                <LoginButton
                    textColor={otpButton?.textColor}
                    iconSrc={otpButton?.icon}
                    iconSize={'32px'}
                    backgroundColor={otpButton?.backgroundColor}
                    text={otpButton?.text}
                    callback={() =>
                        loginButtonClickWrapper(
                            () => setMode(ELoginMode.OTP),
                            ELoginMethods.OTP
                        )
                    }
                    dataTestId="otp-login-button"
                />
            )}
            {!isWebview && playerAuthData.fbOn && playerAuthData.fbAppId && (
                <FacebookLogin
                    loginButtonClickWrapper={loginButtonClickWrapper}
                />
            )}
            {!isWebview &&
                playerAuthData.googleOn &&
                playerAuthData.googleAppId && (
                    <GoogleLogin
                        stopLoader={stopLoader}
                        loginButtonClickWrapper={loginButtonClickWrapper}
                        loginResponse={loginResponse}
                    />
                )}
            {!isWebview &&
                playerAuthData.appleOn &&
                playerAuthData.appleAppId && (
                    <AppleLogin
                        clientId={playerAuthData.appleAppId}
                        redirectURI={`${window.location.href}?apple=true`}
                        responseType={
                            playerAuthData.appleResponseType ===
                            EAppleResponseType.CODE
                                ? EAppleResponseType.CODE
                                : EAppleResponseType.ID_TOKEN
                        }
                        responseMode={
                            playerAuthData.appleResponseType ===
                            EAppleResponseType.CODE
                                ? 'query'
                                : 'fragment'
                        }
                        callback={appleResponseHandler}
                        render={(renderProps) => (
                            <LoginButton
                                variant="apple"
                                callback={() =>
                                    loginButtonClickWrapper(
                                        renderProps.onClick,
                                        ELoginMethods.APPLE
                                    )
                                }
                                text={t('login.signInWithApple')}
                            >
                                <img
                                    src="https://media.appcharge.com/defaults/apple_icon_white.svg"
                                    alt="apple icon"
                                />
                            </LoginButton>
                        )}
                    />
                )}
            {!isWebview && playerAuthData.usernamePasswordOn && (
                <LoginButton
                    variant="transparent"
                    text={t('login.loginWithUserName')}
                    callback={() =>
                        loginButtonClickWrapper(
                            () => setMode(ELoginMode.USERNAME),
                            ELoginMethods.USERNAME
                        )
                    }
                />
            )}
            {!isWebview && playerAuthData.userTokenOn && (
                <LoginButton
                    variant="transparent"
                    text={t('login.loginWithPlayerId')}
                    callback={() =>
                        loginButtonClickWrapper(
                            () => setMode(ELoginMode.TOKEN_ID),
                            ELoginMethods.TOKEN
                        )
                    }
                />
            )}
        </>
    );
};
