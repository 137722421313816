import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { i18n } from './i18n';
import App from './App';
import { NewRelicProvider } from './components/NewRelicProvider';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const queryClient = new QueryClient();

root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
                <NewRelicProvider>
                    <App />
                </NewRelicProvider>
            </QueryClientProvider>
        </I18nextProvider>
    </BrowserRouter>
);

