import { GoogleOAuthProvider } from '@react-oauth/google';
import { StyledImageContainer, StyledLoginPage } from '../style';
import { Box, Button } from '@mui/material';
import { LoginTermsBlock } from './TermsBlock';
import { ProductMadnessLogin } from '../Custom/ProductMadness/ProductMadnessLogin';
import React from 'react';
import { ILoginProps } from '../types';
import { useTranslation } from 'react-i18next';
import useLoginEvents from '../../../hooks/useLoginEvents';
import {
    ELoginEvents,
    ELoginMode,
    EOTPTextModel
} from '../../../constants/enums';
import { isDesktop } from 'react-device-detect';
import { WebviewEscapeScreen } from '../WebviewEscapeScreen/WebviewEscapeScreen';
import { LoginButtonBlocks } from './ButtonBlocks';
import { useLoginPage } from '../useLoginPage.hook';

export const Login: React.FC<ILoginProps> = (props: ILoginProps) => {
    const { setMode, mode, openInfoModal } = props;
    const { trackLoginEvent } = useLoginEvents();
    const { publisherMetaData, isWebview, onContactSupport, isProductMadness } =
        useLoginPage();
    const { t } = useTranslation();

    const {
        loginLogoSize,
        textSize = 24,
        textColor = 'blue',
        text = 'Welcome to appcharge store!',
        textWeight = 'normal'
    } = publisherMetaData.storeTheme.login;
    const logo =
        publisherMetaData.storeTheme.login?.loginLogoImage ||
        publisherMetaData.storeTheme.general?.logo ||
        'https://media.appcharge.com/defaults/logo.svg';
    const playerAuthData = publisherMetaData.integration.playersAuthentication;
    const fontFamily =
        publisherMetaData.storeTheme.login.font ||
        publisherMetaData.storeTheme.general.font;
    const { privacyPolicyUrl, termsAndConditionsUrl } =
        publisherMetaData.publisher;
    const showOtpButton =
        playerAuthData.otpOn &&
        (isDesktop
            ? playerAuthData?.otpTextModel &&
              playerAuthData?.otpTextModel !== EOTPTextModel.MOBILE_ONLY
            : true);

    const handleBackBtnClicked = async () => {
        setMode(ELoginMode.PROVIDERS);
        await trackLoginEvent(ELoginEvents.LOGIN_CANCELED);
    };

    const displayTermsBlock = !isWebview || showOtpButton;

    return (
        <GoogleOAuthProvider clientId={playerAuthData?.googleAppId || ''}>
            {isProductMadness ? (
                <ProductMadnessLogin
                    loginProps={props}
                    handleBackBtnClicked={handleBackBtnClicked}
                />
            ) : (
                <StyledLoginPage
                    fontFamily={fontFamily}
                    textColor={textColor}
                    textSize={textSize}
                    textWeight={textWeight}
                >
                    <StyledImageContainer>
                        <img
                            className="logo"
                            id={loginLogoSize}
                            alt="game logo"
                            src={logo}
                            style={{
                                width: window.location.hostname.includes(
                                    'matchmaste.rs'
                                )
                                    ? '230px'
                                    : 'auto'
                            }}
                        />
                    </StyledImageContainer>
                    {isWebview && mode === ELoginMode.PROVIDERS ? (
                        <>
                            <WebviewEscapeScreen />
                            {showOtpButton && (
                                <div className="orText">{t('login.or')}</div>
                            )}
                        </>
                    ) : (
                        <h2>{text}</h2>
                    )}
                    <LoginButtonBlocks {...props} />
                    {displayTermsBlock && (
                        <LoginTermsBlock
                            textColor={textColor}
                            openInfoModal={openInfoModal}
                            privacyPolicyLink={privacyPolicyUrl}
                            termsAndConditionsLink={termsAndConditionsUrl}
                        ></LoginTermsBlock>
                    )}
                    {!isWebview && (
                        <Box mt={2}>
                            <Button
                                variant="text"
                                sx={{
                                    color: 'white',
                                    textTransform: 'none',
                                    fontSize: '16px'
                                }}
                                onClick={() => onContactSupport?.()}
                            >
                                {t('contactSupport')}
                            </Button>
                        </Box>
                    )}
                    {mode !== ELoginMode.PROVIDERS && (
                        <button
                            className="backButton"
                            onClick={handleBackBtnClicked}
                        >
                            {t('login.back')}
                        </button>
                    )}
                </StyledLoginPage>
            )}
        </GoogleOAuthProvider>
    );
};
