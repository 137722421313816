import { LoginButton, LoginInput } from '@appcharge/shared-ui';
import { ELoginEvents, ELoginMethods } from '../../../constants/enums';
import { ManualModeProps } from '../types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLoginEvents from '../../../hooks/useLoginEvents';
import { useLoginPage } from '../useLoginPage.hook';

export const UsernamePasswordMode: React.FC<ManualModeProps> = ({
    submit,
    loginButtonClickWrapper
}) => {
    const { t } = useTranslation();
    const { trackLoginEvent } = useLoginEvents();
    const { isWebview } = useLoginPage();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const isDisabled = !username.trim().length || !password.trim().length;

    useEffect(() => {
        const trackLogin = async () => {
            if (trackLoginEvent) {
                await trackLoginEvent(
                    ELoginEvents.LOGIN_SCREEN_PRESENTED,
                    ELoginMethods.USERNAME
                );
            }
        };
        trackLogin();
    }, []);

    if (isWebview) return <></>;

    const handleFormSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        if (isDisabled) return;
        if (trackLoginEvent) {
            await trackLoginEvent(
                ELoginEvents.LOGIN_APPROVAL,
                ELoginMethods.USERNAME
            );
        }
        loginButtonClickWrapper(
            () =>
                submit({
                    authMethod: ELoginMethods.USERNAME,
                    userName: username,
                    userPassword: password
                }),
            ELoginMethods.USERNAME,
            ELoginEvents.PLAYER_PASSWORD_BUTTON_CLICKED
        );
    };

    return (
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
            <LoginInput
                type="text"
                placeholder={t('login.userName')}
                value={username}
                setValue={setUsername}
            />
            <LoginInput
                type="password"
                placeholder={t('login.password')}
                value={password}
                setValue={setPassword}
            />
            <LoginButton
                type="submit"
                variant="white"
                text={t('login.login')}
                isDisabled={isDisabled}
            />
        </form>
    );
};
