import { browserName, isMobile, osName } from 'react-device-detect';
import { BootResponse } from '../constants/apiResponses.types';
import {
    EEventsType,
    EPublisherWebhookEventType,
    ESessionStorageKeys,
    EStorePhase,
    ELoginMode,
    ELoginMethods
} from '../constants/enums';
import { loginPageService } from '../pages/login/login-page.service';
import { localStorageUtil } from '../utils';
import useApi from './useApi';
import useCustomEvents from './useCustomEvents';

export default function useLoginEvents() {
    const API = useApi({
        platform: isMobile ? osName : browserName
    });
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const customEvents = useCustomEvents();

    const sendPublisherWebhookLoginEvent = async (
        eventName: EEventsType,
        method?: any,
        options?: Record<string, string>
    ) => {
        const loginData = loginPageService.getLoginEventsData({
            eventName,
            gameName: publisherMetaData.publisher.storeTabName,
            method,
            options
        });
        try {
            API.sendPublisherWebhookEvent.mutate({
                eventType: EPublisherWebhookEventType.LOGIN,
                data: loginData
            });
        } catch (error) {
            console.error(error);
        }
    };

    const trackLoginEvent = async (
        eventName: string,
        method?: ELoginMethods
    ) => {
        const redirectState = localStorageUtil.get(
            ESessionStorageKeys.REDIRECT_STATE
        );

        let loginMethod;

        if (method) {
            loginMethod = method;
        } else {
            const { pathname } = window.location;

            if (pathname.includes(ELoginMode.TOKEN_ID)) {
                loginMethod = ELoginMethods.TOKEN;
            } else if (pathname.includes(ELoginMode.USERNAME)) {
                loginMethod = ELoginMethods.USERNAME;
            } else if (pathname.includes(ELoginMode.OTP)) {
                loginMethod = ELoginMethods.OTP;
            } else {
                return;
            }
        }

        await sendPublisherWebhookLoginEvent(
            eventName as EEventsType,
            loginMethod
        );
        if (eventName === EEventsType.LOGIN_CANCELED && redirectState) {
            customEvents.sendCustomEvent(
                EEventsType.LOGIN_CANCELED,
                loginPageService.getLoginEventsData({
                    eventName: EEventsType.LOGIN_CANCELED,
                    gameName: publisherMetaData.publisher?.storeTabName
                }),
                EStorePhase.PRE_LOGIN
            );
            localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
        }
    };

    return {
        sendPublisherWebhookLoginEvent,
        trackLoginEvent
    };
}
