import React, { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { MuiOtpInput } from 'mui-one-time-password-input';
import CheckIcon from '@mui/icons-material/Check';
import { isAndroid, isDesktop, isIOS, isTablet } from 'react-device-detect';
import { OTPModeProps } from '../types';
import {
    ELoginEvents,
    ELoginMethods,
    EOTPTextModel
} from '../../../constants/enums';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '@appcharge/shared-ui';
import useLoginEvents from '../../../hooks/useLoginEvents';
import { useLoginPage } from '../useLoginPage.hook';

const REDIRECT_MESSAGE_TIMEOUT = 10000;

export const OtpMode: React.FC<OTPModeProps> = ({
    submit,
    loginButtonClickWrapper,
    otpProofKey,
    otpTokenKey
}) => {
    const { publisherMetaData, API, isWebview } = useLoginPage();
    const { t } = useTranslation();
    const { trackLoginEvent } = useLoginEvents();
    const [otp, setOtp] = useState('');
    const [isRedirected, setIsRedirected] = useState(false);
    const [otpConfigResponse, setConfigOtpResponse] = useState<any>();
    const [isLoadingOtpConfig, setIsLoadingOtpConfig] = useState(true);
    const [otpConfigError, setOtpConfigError] = useState();
    const [isOTPButtonClicked, setIsOTPButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const numOfDigits = useMemo(
        () =>
            publisherMetaData.integration.playersAuthentication.otpTextModel ===
            EOTPTextModel.SIX_DIGITS
                ? 6
                : 4,
        [publisherMetaData.integration.playersAuthentication]
    );

    const otpButton = useMemo(
        () => publisherMetaData.storeTheme.login.otpButton,
        [publisherMetaData.storeTheme.login.otpButton]
    );

    useEffect(() => {
        setIsLoadingOtpConfig(true);
        if (!otpProofKey) {
            API.getOTP?.()
                .then((response) => {
                    setConfigOtpResponse(response);
                    setIsLoadingOtpConfig(false);
                })
                .catch((e) => {
                    setOtpConfigError(e);
                });
        }
    }, []);

    const login = (otpValue: string, accessToken?: string) => {
        submit({
            authMethod: ELoginMethods.OTP,
            otp: {
                playerCode: otpValue,
                accessToken:
                    accessToken ||
                    Cookies.get('otp_accessToken') ||
                    otpConfigResponse?.data?.accessToken
            }
        });
    };

    if (otpProofKey) {
        if (otpTokenKey) {
            login(otpProofKey, otpTokenKey);
        } else {
            login(otpProofKey);
        }
        return null;
    }

    useEffect(() => {
        if (otpConfigResponse?.data?.accessToken) {
            const now = new Date();
            Cookies.set(
                'otp_accessToken',
                otpConfigResponse?.data?.accessToken,
                {
                    expires: now.setMinutes(now.getMinutes() + 5)
                }
            );
        }
    }, [otpConfigResponse?.data]);

    const handleChange = (newValue: string) => {
        setOtp(newValue);
        if (newValue.length >= numOfDigits!) handleComplete(newValue);
    };

    const handleComplete = async (otpValue: string) => {
        loginButtonClickWrapper(
            () => login(otpValue),
            ELoginMethods.OTP,
            ELoginEvents.OTP_CODE_ENTERED
        );
        if (trackLoginEvent) {
            await trackLoginEvent(
                ELoginEvents.LOGIN_APPROVAL,
                ELoginMethods.OTP
            );
        }
        (document.activeElement as HTMLElement).blur();
    };

    useEffect(() => {
        const trackLogin = async () => {
            if (
                !isLoadingOtpConfig &&
                !otpConfigError &&
                otpConfigResponse?.data &&
                trackLoginEvent
            ) {
                await trackLoginEvent(
                    ELoginEvents.LOGIN_SCREEN_PRESENTED,
                    ELoginMethods.OTP
                );
            }
        };
        trackLogin();
    }, [isLoadingOtpConfig, otpConfigError]);

    useEffect(() => {
        const trackShownOtpLoginBtn = async () => {
            if (
                !isDesktop &&
                (isIOS || isAndroid) &&
                !isOTPButtonClicked &&
                trackLoginEvent
            ) {
                await trackLoginEvent(
                    ELoginEvents.LOGIN_SCREEN_PRESENTED,
                    ELoginMethods.OTP
                );
            }
        };
        trackShownOtpLoginBtn();
    }, [isOTPButtonClicked]);

    const handleAuthenticateMeBtnClick = async () => {
        setIsLoading(true);
        window.location.replace(otpConfigResponse.data.deepLink);
        setIsOTPButtonClicked(true);
        loginButtonClickWrapper(
            () => {},
            ELoginMethods.OTP,
            ELoginEvents.OTP_BUTTON_CLICKED
        );
        setTimeout(() => {
            setIsRedirected(true);
            setIsLoading(false);
        }, REDIRECT_MESSAGE_TIMEOUT);

        if (trackLoginEvent) {
            await trackLoginEvent(
                !isDesktop && (isIOS || isAndroid || isTablet)
                    ? ELoginEvents.LOGIN_OTP_IOS_BUTTON
                    : ELoginEvents.LOGIN_OTP_ANDROID_BUTTON,
                ELoginMethods.OTP
            );
        }
    };

    if (otpConfigError) {
        window.location.replace('/failed');
    }

    if (isLoadingOtpConfig) {
        return (
            <Stack
                pb={4}
                width="100%"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress
                    sx={{
                        color: 'white'
                    }}
                />
            </Stack>
        );
    }

    return (
        <>
            {isDesktop && (!isIOS || !isAndroid) && (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={'column'}
                >
                    <Box className="otp-container" gap={2} p={2}>
                        <Typography data-testid="otp-qr-message">
                            {t('login.otp.instructions')}
                        </Typography>
                        <Box className="qr">
                            <QRCode
                                size={80}
                                style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '100%'
                                }}
                                value={otpConfigResponse.data.deepLink || ''}
                                viewBox={`0 0 80 80`}
                                data-testid="otp-qr-code"
                            />
                        </Box>
                    </Box>
                    <h2>{t('login.otp.enterCode')}</h2>
                    <MuiOtpInput
                        value={otp}
                        onChange={handleChange}
                        length={numOfDigits}
                        autoFocus={true}
                    />
                </Box>
            )}
            {!isLoading && isRedirected && (
                <Box
                    className="otp-container"
                    flexDirection="column"
                    gap={2}
                    p={isWebview ? 0 : 2}
                    style={{ width: '100%' }}
                >
                    <CheckIcon fontSize="large" />
                    <Typography textAlign="center">
                        {t('login.otp.redirected')}
                        <br />
                        {t('login.otp.closeTab')}
                    </Typography>
                </Box>
            )}
            {!(isDesktop || isOTPButtonClicked) && (isIOS || isAndroid) && (
                <Box
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    p={isWebview ? 0 : 2}
                    width={'100%'}
                >
                    <ActionButton
                        text="Authenticate me now!"
                        action={handleAuthenticateMeBtnClick}
                        colors={otpButton.backgroundColor}
                        textColor={otpButton.textColor.colorOne}
                        icon={otpButton.icon}
                        borderRadius="8px"
                        dataTestId="otp-authenticate-button"
                        width={'100%'}
                    />
                </Box>
            )}
        </>
    );
};
