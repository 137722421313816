import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import resources from './locales/translations';
import { SupportedLanguage } from './constants/apiRequest.types';
import { localStorageUtil } from './utils';
import { ELocalStorageKeys } from './constants/enums';


i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en',
    resources,
    interpolation: {
        escapeValue: false,
    },
    ns: ['common'],
    defaultNS: 'common',
    preload: ['en'],
    supportedLngs: ['en'],
});

const fetchSupportedLanguages = async (getLanguagesList: (publisherId: string) => Promise<SupportedLanguage[]>, publisherId: string ) => {
    let supportedLanguagesList =  ['en'];
    try {
        const languagesList = await getLanguagesList(publisherId);
        const existingLanguages = new Set<string>();

        if (languagesList.length > 0) {
            supportedLanguagesList = languagesList
                .filter((item: SupportedLanguage) =>
                    !existingLanguages.has(item.language) && existingLanguages.add(item.language))
                .map((item: SupportedLanguage) => item.language);
        }
        return supportedLanguagesList
    } catch (error) {
        console.error('Error fetching supported languages:', error);
        return ['en'];
    }
}

const initI18n = async (getLanguagesList: (publisherId: string) => Promise<SupportedLanguage[]>, publisherId: string) => {
    try {
        const initialLanguage = localStorageUtil.get(ELocalStorageKeys.DEFAULT_LANGUAGE);
        const supportedLanguages = await fetchSupportedLanguages(getLanguagesList, publisherId);
        const defaultLanguage = initialLanguage && supportedLanguages.includes(initialLanguage) ? initialLanguage : supportedLanguages[0];

        if (!initialLanguage) {
            localStorageUtil.set(ELocalStorageKeys.DEFAULT_LANGUAGE, supportedLanguages[0])
        }

        await i18n
            .use(Backend)
            .init({
                backend: {
                    backends: [
                        LocalStorageBackend,
                        HttpBackend
                    ],
                    backendOptions: [
                        { prefix: 'i18next_res_', expirationTime: 7 * 24 * 60 * 60 * 1000},
                        { loadPath: `https://api-store-dev.appcharge.com/store/translations/${publisherId}/languages/{{lng}}` }
                    ]
                },
                supportedLngs: supportedLanguages,
                lng: defaultLanguage,
                fallbackLng: 'en',
                debug: process.env.NODE_ENV !== 'production'
        });

        i18n.emit('supportedLanguagesChanged', supportedLanguages);

        if (defaultLanguage !== 'en') {
            await changeLanguage(defaultLanguage);
        }
    } catch (error) {
        console.error('Error during i18n initialization:', error);
    }
}

const changeLanguage = async (language: string) => {
    try {
        await i18n.reloadResources(language);
        await i18n.changeLanguage(language);

        localStorageUtil.set(
            ELocalStorageKeys.DEFAULT_LANGUAGE,
            language
        );


    } catch (error) {
        console.error(`Error changing language to ${language}:`, error);
        if (language !== 'en') {
            await i18n.changeLanguage('en');
        }
    }
}


export {
    i18n,
    initI18n,
    changeLanguage
};
