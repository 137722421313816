import styled from 'styled-components';
import { ILoginProps } from './types';

export const StyledLoginPage = styled.div`
    background-color: transparent;
    width: 100vw;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-family: ${(props: any) => props.fontFamily};
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 4em;

    & > * {
        margin-top: 20px;
    }

    a {
        color: white;
    }

    h2 {
        max-width: 90%;
        color: ${(props: any) => props.textColor};
        font-size: ${(props: any) => props.textSize}px;
        font-weight: ${(props: any) => props.textWeight};
        text-align: center;
        font-family: ${(props: any) => props.fontFamily};
    }

    button {
        font-family: ${(props: any) => props.fontFamily};
    }

    .usernameContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .orText {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: ${(props: any) => props.textColor};
        margin-top: 4px;
    }
` as any;

export const StyledLoginContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${(props: any) => props.maxWidth};
    width: 100%;

    img.loginButton {
        margin-bottom: 12px;
        cursor: pointer;

        &:active {
            transform: scale(95%);
        }
    }

    span {
        text-align: center;
        max-width: 80%;
        font-size: 12px;

        & > span {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .MuiOtpInput-Box {
        margin-bottom: 20px;
        width: 500px;
        max-width: 90%;

        & div {
            width: 100%;
            aspect-ratio: 1/1;
        }

        input {
            width: 100%;
            height: 100%;
            padding: 8px;
            border: 1px solid white;
            box-sizing: border-box;
            color: white;
            font-size: 24px;
        }

        *:hover .MuiOutlinedInput-notchedOutline {
            border: 0 !important;
        }

        .Mui-focused {
            fieldset.MuiOutlinedInput-notchedOutline {
                border: 0;
            }
        }
    }

    .userTokenLink {
        color: white;
        text-decoration: underline;
        cursor: pointer;
        margin: 0 0 32px 0;
    }
` as any;

export const StyledImageContainer = styled.div<ILoginProps>`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: auto;

    .logo {
        object-fit: contain;
        height: 100%;
        max-height: 150px;
    }

    #big.logo {
        width: 280px;
        height: 280px;
        max-height: fit-content;

        @media (min-width: 1024px) {
            width: 400px;
            height: 400px;
        }
    }
` as any;

export const StyledLoginTermsBlock = styled.div`
    color: ${(props: any) => props.textColor};
    text-align: center;
    max-width: 80%;
    font-size: ${(props: any) => props.textSize};
    span:not(.terms-wrapper) {
        text-decoration: underline;
        cursor: pointer;
    }
` as any;
