import { useEffect, useState } from 'react';
import { BootResponse } from '../constants/apiResponses.types';

interface InstallPromptEvent extends Event {
    prompt: () => Promise<void>;
}

const useSaveToHomePage = (publisherMetaData: BootResponse) => {
    const [useSaveToHomePageNotification, setUseSaveToHomePageNotification] =
        useState(false);
    const [isSaveToHomePageOpen, setIsSaveToHomePageOpen] = useState(false);
    const [isDisplayAddToHomePageButton, setIsDisplayAddToHomePageButton] =
        useState(true);
    const [installPrompt, setInstallPrompt] =
        useState<InstallPromptEvent | null>(null);
    const [isAndroidFallBack, setIsAndroidFallBack] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
            e.preventDefault();
            console.log('beforeinstallprompt event triggered');
            setInstallPrompt(e);
            sessionStorage.setItem('installPromptFired', 'true');
        };

        window.addEventListener(
            'beforeinstallprompt',
            handleBeforeInstallPrompt
        );

        return () => {
            window.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            );
        };
    }, []);

    useEffect(() => {
        const handleAppInstalled = () => {
            setIsDisplayAddToHomePageButton(false);
            sessionStorage.setItem('source', 'home screen bookmark');
        };

        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    const handleInstallApp = async () => {
        if (installPrompt) {
            try {
                await installPrompt.prompt();
                setInstallPrompt(null);
                sessionStorage.removeItem('installPromptFired');
            } catch (error) {
                console.error('Install prompt failed', error);
            }
        } else {
            setIsAndroidFallBack(true);
            console.log('Install prompt not available');
        }
    };

    useEffect(() => {
        setUseSaveToHomePageNotification(
            !!publisherMetaData.featureFlags
                ?.store_add_to_home_screen_notification
        );
    }, [publisherMetaData]);

    useEffect(() => {
        const isStandalone = window.matchMedia(
            '(display-mode: standalone)'
        ).matches;

        if (isStandalone) {
            setIsDisplayAddToHomePageButton(false);
            setIsSaveToHomePageOpen(false);
            sessionStorage.setItem('source', 'home screen bookmark');
        } else if (sessionStorage.getItem('installPromptFired')) {
            // Handle case where beforeinstallprompt already fired but page reloaded
            console.log('Restoring install prompt state');
            setIsDisplayAddToHomePageButton(true);
        }
    }, []);

    const handleCloseSaveToHomePage = () => {
        setIsSaveToHomePageOpen(false)
        setIsAndroidFallBack(false);
    };

    return {
        useSaveToHomePageNotification,
        isSaveToHomePageOpen,
        setIsSaveToHomePageOpen,
        isDisplayAddToHomePageButton,
        handleCloseSaveToHomePage,
        handleInstallApp,
        isAndroidFallBack
    };
};

export default useSaveToHomePage;
