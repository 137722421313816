import React, { useEffect, useState } from 'react';
import { ManualModeProps } from '../types';
import { ELoginEvents, ELoginMethods } from '../../../constants/enums';
import { LoginButton, LoginInput } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';
import useLoginEvents from '../../../hooks/useLoginEvents';
import { useLoginPage } from '../useLoginPage.hook';

export const LoginIdMode: React.FC<ManualModeProps> = ({
    submit,
    loginButtonClickWrapper
}) => {
    const { t } = useTranslation();
    const { isWebview } = useLoginPage();
    const { trackLoginEvent } = useLoginEvents();
    const [id, setId] = useState('');
    const isDisabled = id.trim().length <= 3;

    useEffect(() => {
        const trackLogin = async () => {
            if (trackLoginEvent) {
                await trackLoginEvent(
                    ELoginEvents.LOGIN_SCREEN_PRESENTED,
                    ELoginMethods.TOKEN
                );
            }
        };
        trackLogin();
    }, []);

    if (isWebview) return <></>;

    const login = () => {
        submit({
            userToken: id,
            authMethod: ELoginMethods.TOKEN
        });
    };

    const loginButtonClicked = async (callback: Function) => {
        if (trackLoginEvent) {
            await trackLoginEvent(
                ELoginEvents.LOGIN_APPROVAL,
                ELoginMethods.TOKEN
            );
        }
        loginButtonClickWrapper(
            callback,
            ELoginMethods.TOKEN,
            ELoginEvents.PLAYER_ID_BUTTON_CLICKED
        );
    };

    const handleFormSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        if (isDisabled) return;
        await loginButtonClicked(login);
    };

    return (
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
            <LoginInput
                type="text"
                placeholder={t('login.playerId')}
                value={id}
                setValue={setId}
            />
            <LoginButton
                type="submit"
                variant="white"
                text={t('login.login')}
                isDisabled={isDisabled}
            />
        </form>
    );
};
