import { LoginButton } from '@appcharge/shared-ui';
import './styles.scss';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useTranslation } from 'react-i18next';
import { ESessionStorageKeys } from '../../../constants/enums';
import { localStorageUtil } from '../../../utils';
import { useLoginPage } from '../useLoginPage.hook';

export const WebviewEscapeScreen: React.FC = () => {
    const { handleWebviewEvents, isProductMadness, publisherMetaData } =
        useLoginPage();
    const { t } = useTranslation();

    const [showInstructions, setShowInstructions] = useState(false);
    const [currentApp, setCurrentApp] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [androidLink, setAndroidLink] = useState<string>('');

    const textColor = useMemo(
        () =>
            publisherMetaData.storeTheme.login.textColor ||
            (isProductMadness ? 'black' : 'blue'),
        [publisherMetaData, isProductMadness]
    );

    const textStyle = {
        color: textColor
    };

    const getRedirectUrl = useCallback(() => {
        const baseAddress = window.location.hostname || window.location.host;
        const sessionData = localStorageUtil.get(
            ESessionStorageKeys.SESSION_DATA
        );

        const sessionId = sessionData?.id || '';

        const params = new URLSearchParams(window.location.search);
        params.append('source', 'webview');
        if (sessionId) {
            params.append('sessionId', sessionId);
        }
        const newUrl = `${baseAddress}?${params.toString()}`;
        console.log('newUrl', newUrl);

        return newUrl;
    }, [window.location]);

    useEffect(() => {
        const redirectUrl = getRedirectUrl();
        setAndroidLink(`intent://${redirectUrl}#Intent;scheme=https;end`);
    }, [window.location]);

    useEffect(() => {
        try {
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('source', 'webview');

            // Replace the current entry in the browser's history stack
            window.history.replaceState(
                null,
                '',
                `${
                    window.location.pathname
                }?${currentUrl.searchParams.toString()}`
            );
        } catch (err: any) {
            console.error('Error updating URL:', err);
        }
    }, []);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('instagram')) {
            setCurrentApp('Instagram');
        } else {
            setCurrentApp('Facebook');
        }
    }, []);

    const openInExternalBrowser = () => {
        const redirectUrl = getRedirectUrl();
        const urlArr = [`googlechrome://${redirectUrl}`];
        urlArr.forEach((url) => {
            try {
                window.open(url);
            } catch (error) {
                console.error(`Failed to open URL in external browser`, error);
            }
        });
        sendButtonClickedEvent();
        setShowInstructions(true);
        setButtonDisabled(true);
    };

    const sendButtonClickedEvent = () => {
        handleWebviewEvents({ event: 'webview_button_click' });
    };

    return (
        <div className={'login__webview-escape-screen'}>
            {showInstructions && (
                <ul className={'login__webview-escape-screen__instructions'}>
                    <li
                        className={
                            'login__webview-escape-screen__instructions__item'
                        }
                    >
                        <IosShareIcon
                            className={
                                'login__webview-escape-screen__instructions__item__icon'
                            }
                        />
                        {t('login.escapeInstructions.stepOne')}
                    </li>
                    <li
                        className={
                            'login__webview-escape-screen__instructions__item'
                        }
                    >
                        <i className="fa-regular fa-compass login__webview-escape-screen__instructions__item__icon"></i>
                        {t('login.escapeInstructions.stepTwo')}
                    </li>
                </ul>
            )}
            <span
                className={'login__webview-escape-screen__title'}
                style={textStyle}
            >
                {`${t('login.leavingWebviewTitle')} ${currentApp}`}
            </span>
            <span
                className={'login__webview-escape-screen__text'}
                style={textStyle}
            >
                {t('login.leavingWebviewDescription')}
            </span>
            <Box maxWidth={'400px'} width={'100%'} marginTop={1}>
                {isAndroid ? (
                    <a
                        href={androidLink}
                        onClick={sendButtonClickedEvent}
                        style={{ textDecoration: 'none' }}
                    >
                        <LoginButton
                            variant={'webview-button'}
                            text={t('login.leavingWebviewButton')}
                        />
                    </a>
                ) : (
                    <>
                        <LoginButton
                            variant={'webview-button'}
                            text={t('login.leavingWebviewButton')}
                            callback={openInExternalBrowser}
                            isDisabled={buttonDisabled}
                        />
                    </>
                )}
            </Box>
        </div>
    );
};
