import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Modal,
    Stack,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { i18n, changeLanguage } from '../../i18n';

const LanguagePicker = ({ fontSize = 16}: { fontSize?: number }) => {
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language ?? 'en');
    const [supportedLanguages, setSupportedLanguages] = useState<string[]>(['en']);

    useEffect(() => {
        const updateSupportedLanguages = () => {
            const languages = Array.isArray(i18n.options.supportedLngs)
                ? i18n.options.supportedLngs.filter(lng => lng !== 'cimode')
                : ['en'];
            if (languages.length > 0) {
                setSupportedLanguages(languages);
            }
        };
        updateSupportedLanguages()
        i18n.on('supportedLanguagesChanged', updateSupportedLanguages);

        return () => {
            i18n.on('supportedLanguagesChanged', updateSupportedLanguages);
        };
    }, []);

    const handleOnClick = async (event: React.MouseEvent<HTMLDivElement>) => {
        const language = event.currentTarget.getAttribute('data-language');
        if (language) {
            await changeLanguage(language);
            setSelectedLanguage(language);
            setIsLanguageModalOpen(false);
        }
    };

    return (
        <>
            <Modal
                className="language-picker-modal"
                open={isLanguageModalOpen}
                onClose={() => setIsLanguageModalOpen(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 2
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '12px'
                        }}
                    >
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{ fontWeight: 600 }}
                        >
                            {'Choose a language'}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            sx={{ p: 0, color: 'black' }}
                            onClick={() => setIsLanguageModalOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: 'black' }} />
                    <List sx={{ marginTop: '12px', p: 0 }}>
                        {supportedLanguages.map((lang, index) => (
                            <ListItem
                                disablePadding
                                key={index}
                                sx={{
                                    marginBottom: '12px',
                                    '&:last-child': {
                                        marginBottom: '0'
                                    }
                                }}
                            >
                                <ListItemButton
                                    data-language={lang}
                                    onClick={handleOnClick}
                                    sx={{
                                        padding: 0
                                    }}
                                >
                                    <ListItemText
                                        sx={{ margin: 0 }}
                                        disableTypography
                                        primary={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <CheckIcon
                                                    sx={{
                                                        color: '#000',
                                                        marginRight: '4px',
                                                        visibility:
                                                            selectedLanguage ===
                                                            lang
                                                                ? 'visible'
                                                                : 'hidden'
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        color:
                                                            selectedLanguage ===
                                                            lang
                                                                ? '#000'
                                                                : '#999999',
                                                        fontSize: '1rem',
                                                        fontWeight:
                                                            selectedLanguage ===
                                                            lang
                                                                ? 600
                                                                : 500,
                                                        fontFamily:
                                                            'Montserrat'
                                                    }}
                                                >
                                                    {lang}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Modal>
            <Button
                variant="text"
                sx={{
                    color: 'white',
                    fontSize: `${fontSize}px`,
                    fontWeight: 'normal',
                    fontFamily: 'Roboto',
                    textTransform: 'none',
                    padding: 0,
                    minWidth: '40px',
                    width: 'initial'
                }}
                onClick={() => setIsLanguageModalOpen(true)}
            >
                <Stack gap={0.5} direction="row">
                    <LanguageIcon
                        sx={{
                            width: '18px'
                        }}
                    />
                    {i18n.language}
                </Stack>
            </Button>
        </>
    );
};

export default LanguagePicker;
