import { Box, Button } from '@mui/material';
import { useIsDesktop, useIsLandscape } from '@appcharge/shared-ui';
import '../../style.scss';
import React, { useEffect, useState } from 'react';
import { LoginTemplateProps } from '../../types';
import { loginPageService } from '../../login-page.service';
import { useTranslation } from 'react-i18next';
import { WebviewEscapeScreen } from '../../WebviewEscapeScreen/WebviewEscapeScreen';
import { LoginTermsBlock } from '../../Components/TermsBlock';
import {
    EBundlesInternalViewModel,
    ELoginMode
} from '../../../../constants/enums';
import { LoginButtonBlocks } from '../../Components/ButtonBlocks';
import { useLoginPage } from '../../useLoginPage.hook';

const saleTextImage =
    'https://media.appcharge.com/media/login-images/sale-text-10%25.png';
const mobileInfoJackpotSlots =
    'https://media.appcharge.com/media/login-images/JMS-how_does_it_work2.png';
const mobileInfoBigFish =
    'https://media.appcharge.com/media/login-images/BFC-infographic-mobile3.png';
const JMS_desktopLogo =
    'https://media.appcharge.com/media/login-images/JMS_login-logo-desk.png';
const BFC_desktopLogo =
    'https://media.appcharge.com/media/login-images/BFC_login-logo-desk.png';
const privacyPolicyLinkCustom = 'https://www.productmadness.com/privacy-notice';
const termsAndConditionsLinkCustom =
    'https://www.productmadness.com/terms-of-service';

export const ProductMadnessLogin: React.FC<LoginTemplateProps> = ({
    loginProps,
    handleBackBtnClicked
}) => {
    const { publisherMetaData, isWebview, onContactSupport } = useLoginPage();
    const { openInfoModal, mode } = loginProps;

    const textColor = publisherMetaData.storeTheme.login.textColor;
    const logo =
        publisherMetaData.storeTheme.login.loginLogoImage ||
        publisherMetaData.storeTheme.general.logo;
    const gameName =
        publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel;

    const { t } = useTranslation();
    const [isOldVersion, setIsOldVersion] = useState<string>('');
    const isDesktop = useIsDesktop();
    const isLandscape = useIsLandscape();
    const desktopLogo =
        gameName === EBundlesInternalViewModel.JACKPOT_SLOTS
            ? JMS_desktopLogo
            : BFC_desktopLogo;

    const mobileHowDoesItWorkImage =
        gameName === EBundlesInternalViewModel.JACKPOT_SLOTS
            ? mobileInfoJackpotSlots
            : mobileInfoBigFish;

    useEffect(() => {
        if (loginPageService.checkIfOlderOsVersions()) {
            setIsOldVersion('ac-login-page--ios13');
        }
    }, []);

    return (
        <>
            <div
                className={`ac-login-page ac-login-page--jackpot-slots ${isOldVersion}`}
                style={{ color: textColor }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className={'ac-login-page__top-container'}
                >
                    <div className={'ac-login-page__logo'}>
                        <img
                            alt="game logo"
                            src={isDesktop || isLandscape ? desktopLogo : logo}
                        ></img>
                    </div>
                    {isWebview ? (
                        <WebviewEscapeScreen />
                    ) : (
                        <>
                            {mode === ELoginMode.PROVIDERS && (
                                <div className={'ac-login-page__sale-image'}>
                                    <img
                                        src={saleTextImage}
                                        alt={'sale-image'}
                                    ></img>
                                </div>
                            )}
                            <LoginButtonBlocks
                                {...loginProps}
                                maxWidth={'320px'}
                            />
                            {mode !== ELoginMode.PROVIDERS && (
                                <button
                                    className="backButton"
                                    onClick={handleBackBtnClicked}
                                >
                                    {t('login.back')}
                                </button>
                            )}
                        </>
                    )}
                </Box>
            </div>
            <div className={'ac-login-page__footer-container ' + gameName}>
                {!(isDesktop || isLandscape) &&
                    mode === ELoginMode.PROVIDERS && (
                        <img
                            className="ac-login-page__footer-container__image"
                            src={mobileHowDoesItWorkImage}
                            alt={'infographic'}
                        />
                    )}
                <div
                    className={`ac-login-page__footer-container__footer-text ${gameName}`}
                >
                    <LoginTermsBlock
                        textSize={'12px'}
                        openInfoModal={openInfoModal}
                        privacyPolicyLink={privacyPolicyLinkCustom}
                        termsAndConditionsLink={termsAndConditionsLinkCustom}
                    ></LoginTermsBlock>
                    <div className={'login-terms-text'}>
                        {t('login.termsText')}
                    </div>
                    <Button
                        variant="text"
                        sx={{
                            textTransform: 'none',
                            fontSize: '12px',
                            padding: '5px 0 0'
                        }}
                        onClick={() => onContactSupport?.()}
                        className={'contact-support-button'}
                    >
                        {t('contactSupport')}
                    </Button>
                </div>
            </div>
        </>
    );
};
